import { configureStore } from "@reduxjs/toolkit";
import Storage from "./Storage";
import Reducers from "./Reducers/RootReducer";

const Store = configureStore({
    reducer: Reducers,
    preloadedState: Storage.LoadState(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
    devTools: process.env.NODE_ENV === 'development' ? true : false
});

Store.subscribe(() => {
    Storage.SaveState(Store.getState());
});

export default Store;