import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

function InfoIcon({ width = 9, height = 15, color: iconColor, ...restProps }) {
  const theme = useTheme();
  const color = iconColor || theme.colors.color.primary;
  return (
    <SVGContainer {...restProps}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.35361 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z"
          fill="currentColor"
        />
        <path
          d="M8.00033 6.66669H7.33366C7.15685 6.66669 6.98728 6.73693 6.86225 6.86195C6.73723 6.98697 6.66699 7.15654 6.66699 7.33335C6.66699 7.51016 6.73723 7.67973 6.86225 7.80476C6.98728 7.92978 7.15685 8.00002 7.33366 8.00002H8.00033V12C8.00033 12.1768 8.07056 12.3464 8.19559 12.4714C8.32061 12.5964 8.49018 12.6667 8.66699 12.6667C8.8438 12.6667 9.01337 12.5964 9.1384 12.4714C9.26342 12.3464 9.33366 12.1768 9.33366 12V8.00002C9.33366 7.6464 9.19318 7.30726 8.94313 7.05721C8.69309 6.80716 8.35395 6.66669 8.00033 6.66669Z"
          fill="currentColor"
        />
        <path
          d="M8 5.33331C8.55228 5.33331 9 4.8856 9 4.33331C9 3.78103 8.55228 3.33331 8 3.33331C7.44772 3.33331 7 3.78103 7 4.33331C7 4.8856 7.44772 5.33331 8 5.33331Z"
          fill="currentColor"
        />
      </svg>
    </SVGContainer>
  );
}

export default InfoIcon;
