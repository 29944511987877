const { API_CALL_PROGRESS } = require("Redux/Constants");

const initialState = {
    apiCallProgress: false
}

const Ui = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case API_CALL_PROGRESS:
            return {
                ...state,
                apiCallProgress: payload
            }

        default:
            return state;
    }
}

export default Ui;