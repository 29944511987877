const getAPIURL = () => {
  // const isServer = typeof window === 'undefined';
  // const baseURL = isServer ? 'http://localhost:3000' : window.location.origin;
  const apiURL =
    process.env.PUBLIC_API_URL ||
    (process.env.NODE_ENV === "development"
      ? "https://api.tradersterminal.in" // TODO:uncomment: "https://tradersterminal.intellexio.com"
      : "https://api.tradersterminal.in");
  return apiURL;
};
const getWebsocketUrl = () => {
  // const isServer = typeof window === 'undefined';
  // const baseURL = isServer ? 'http://localhost:3000' : window.location.origin;
  const apiURL =
    process.env.PUBLIC_API_URL ||
    (process.env.NODE_ENV === "development"
      ? "wss://ws.tradersterminal.in" //TODO:uncomment: "wss://tradersterminalws.intellexio.com"
      : "wss://ws.tradersterminal.in");
  return apiURL;
};
const apiURL = getAPIURL();
const websocketUrl = getWebsocketUrl();

const Config = {
  API_BASE_URL: apiURL,
  API_URL: `${apiURL}/api/v1`,
  WebSocketUrl: websocketUrl,
};

export default Config;
