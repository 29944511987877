import { styled } from '@mui/material/styles';

const SVGContainer = styled('span')`
   font-size: 0;
   line-height: 1;
   display: inline-block;
`;


export default SVGContainer;
