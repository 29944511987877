import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { EyeOpenIcon, EyeCloseIcon } from 'Helpers/Icons';

const MuiPasswordField = (props) => {
    const { label, fullWidth } = props;

    const [isPasswordShow, setIsPasswordShow] = useState(false);

    const togglePasswordShow = () => setIsPasswordShow(!isPasswordShow);

    return (
        <TextField
            {...props}
            autoComplete='new-password'
            label={label}
            type={isPasswordShow ? 'text' : 'password'}
            fullWidth={fullWidth}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton onClick={togglePasswordShow}>
                            {isPasswordShow ? <EyeCloseIcon /> : <EyeOpenIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}

MuiPasswordField.propTypes = {
    label: PropTypes.string,
    fullWidth: PropTypes.bool.isRequired
}

MuiPasswordField.defaultProps = {
    fullWidth: true
}

export default MuiPasswordField