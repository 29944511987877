import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function LogoIcon({ width = 226, height = 38, color: iconColor, ...restProps }) {
   const theme = useTheme();
   const color = iconColor || theme.colors.color.primary;
   return (
      <SVGContainer {...restProps}>
         <svg width={width}
            height={height} color={color} viewBox="0 0 226 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.4122 13.0476H38.9688V33.2673H36.2144V13.0476H28.771V10.3257H46.4122V13.0476Z" fill="#019848" />
            <path d="M50.7296 18.621C48.6638 18.621 46.8931 19.6903 46.8931 21.7641V33.2673H44.2699V21.7641C44.2699 17.9729 47.6473 16.1583 50.6312 16.1583C51.1231 16.1583 51.6149 16.1907 52.1068 16.2879V18.7182C51.6149 18.621 51.2215 18.621 50.7296 18.621Z" fill="#00036E" />
            <path d="M62.62 23.8055V21.5697C62.62 19.6255 60.6197 18.5562 58.6195 18.5562C57.0784 18.5562 55.4389 19.107 54.4224 20.3707L52.7828 18.783C54.3568 17.0656 56.6849 16.1583 58.8163 16.1583C62.0953 16.1583 65.2432 17.9405 65.2432 21.5697V32.4572C64.0627 32.8785 62.8823 33.1701 61.7018 33.3321C60.6525 33.4941 59.6032 33.5913 58.5867 33.5913H58.226C54.8159 33.5913 52.1598 31.9064 52.1598 28.342C52.1598 24.648 55.2749 22.9954 58.554 22.9954C59.9639 22.9954 61.3411 23.2546 62.62 23.8055ZM58.8163 25.0368C56.7177 25.0368 54.7503 25.9117 54.7503 28.342C54.7503 30.513 56.2914 31.3555 58.2916 31.3879H59.013C59.5049 31.3879 59.9639 31.3879 60.4558 31.3555C61.21 31.3231 61.9314 31.2259 62.62 31.0639V25.6849C61.5707 25.3285 60.2263 25.0368 58.8163 25.0368Z" fill="#00036E" />
            <path d="M67.351 28.0179V21.7965C67.351 18.1673 70.63 16.1907 73.9091 16.1907C75.3518 16.1907 76.6635 16.4499 77.8767 17.2276V10.5849H80.4999V32.3924C78.2702 33.1377 76.0404 33.5913 73.8435 33.5913C70.5972 33.4941 67.351 31.7767 67.351 28.0179ZM73.8435 31.2907C75.1551 31.2907 76.5979 31.1935 77.8767 30.8046V19.431C76.5979 18.783 75.1879 18.4913 73.9091 18.4913C71.9416 18.4913 70.007 19.5931 70.007 21.7965V27.9855C70.007 29.9297 71.9089 31.2907 73.8435 31.2907Z" fill="#00036E" />
            <path d="M85.4428 26.171V28.2124C85.4428 30.2538 87.3774 31.1935 89.3777 31.1935C90.9188 31.1935 92.5583 30.4806 93.2469 28.9252L95.5423 29.8973C94.329 32.36 91.8369 33.5913 89.3777 33.5913C86.0986 33.5913 82.7868 31.7767 82.7868 28.0179V21.7317C82.7868 17.9729 86.0986 16.1583 89.3777 16.1583C92.6239 16.1583 95.8702 17.9729 95.8702 21.7317V26.171H85.4428ZM93.3781 23.9999V21.6021C93.3781 19.6579 91.3123 18.5237 89.3777 18.5237C87.1479 18.5237 85.4428 19.6255 85.4428 21.6669V23.9999H93.3781Z" fill="#00036E" />
            <path d="M104.339 18.621C102.273 18.621 100.503 19.6903 100.503 21.7641V33.2673H97.8796V21.7641C97.8796 17.9729 101.257 16.1583 104.241 16.1583C104.733 16.1583 105.225 16.1907 105.716 16.2879V18.7182C105.225 18.621 104.831 18.621 104.339 18.621Z" fill="#00036E" />
            <path d="M112.394 31.1935C113.968 31.1935 115.542 30.2214 115.542 28.5364C115.542 26.7542 113.673 26.2682 112.197 26.0089C109.41 25.4905 106.229 24.7128 106.229 21.0836C106.229 17.5516 109.607 16.1583 112.394 16.1583C114.197 16.1583 116.099 16.774 117.509 18.1673L115.902 20.0143C114.853 18.945 113.574 18.5562 112.394 18.5562C110.918 18.5562 108.82 19.107 108.82 21.0188C108.82 22.9954 111.181 23.3195 112.656 23.5787C115.279 24.0971 118.132 25.1664 118.132 28.5688C118.132 31.9388 115.279 33.5913 112.394 33.5913C109.738 33.5913 107.246 32.684 105.574 30.0918L107.541 28.7632C108.689 30.4482 110.656 31.1935 112.394 31.1935Z" fill="#00036E" />
            <path d="M137.309 12.9543H129.887V33.2658H127.14V12.9543H119.718V10.22H137.309V12.9543Z" fill="#019848" />
            <path d="M138.519 26.1372V28.1879C138.519 30.2386 140.448 31.1826 142.442 31.1826C143.979 31.1826 145.614 30.4664 146.3 28.904L148.589 29.8805C147.379 32.3544 144.894 33.5913 142.442 33.5913C139.172 33.5913 135.87 31.7685 135.87 27.9926V21.6778C135.87 17.9019 139.172 16.0791 142.442 16.0791C145.679 16.0791 148.916 17.9019 148.916 21.6778V26.1372H138.519ZM146.431 23.9563V21.5476C146.431 19.5946 144.371 18.4553 142.442 18.4553C140.219 18.4553 138.519 19.562 138.519 21.6127V23.9563H146.431Z" fill="#00036E" />
            <path d="M157.361 18.553C155.301 18.553 153.535 19.6271 153.535 21.7104V33.2658H150.92V21.7104C150.92 17.9019 154.287 16.0791 157.263 16.0791C157.753 16.0791 158.243 16.1117 158.734 16.2093V18.6506C158.243 18.553 157.851 18.553 157.361 18.553Z" fill="#00036E" />
            <path d="M165.617 16.0791C167.481 16.0791 169.41 16.665 170.718 17.9996C171.993 16.6976 173.922 16.0791 175.491 16.0791C179.153 16.0791 181.834 17.8368 181.834 21.7104V33.2658H179.219V21.5151C179.219 19.1063 177.42 18.4553 175.459 18.4553C173.922 18.4553 172.026 19.3342 172.026 20.8966V33.2658H169.475V21.0919C169.475 19.2691 167.579 18.4553 165.519 18.4553C164.342 18.4553 163.165 18.7483 162.478 19.2365V33.2658H159.863V17.609C161.432 16.7301 163.557 16.0791 165.617 16.0791Z" fill="#00036E" />
            <path d="M183.991 11.7174C183.991 10.6432 184.841 9.79688 185.92 9.79688C186.999 9.79688 187.849 10.6432 187.849 11.7174C187.849 12.7915 186.999 13.6378 185.92 13.6378C184.841 13.6378 183.991 12.7915 183.991 11.7174ZM187.195 16.4046V33.2658H184.448V16.4046H187.195Z" fill="#00036E" />
            <path d="M202.987 21.6127V33.2658H200.404V21.5151C200.404 19.4318 198.41 18.4879 196.612 18.4879C195.075 18.4879 193.571 18.8785 192.623 19.2365V33.2658H190.007V17.4788C192.198 16.7301 194.486 16.0791 196.612 16.0791C199.848 16.1768 202.987 17.8694 202.987 21.6127Z" fill="#00036E" />
            <path d="M215.136 23.761V21.5151C215.136 19.562 213.141 18.4879 211.147 18.4879C209.61 18.4879 207.975 19.0412 206.962 20.3107L205.327 18.7157C206.896 16.9905 209.218 16.0791 211.343 16.0791C214.613 16.0791 217.751 17.8694 217.751 21.5151V32.452C216.574 32.8752 215.397 33.1681 214.22 33.3309C213.174 33.4936 212.128 33.5913 211.114 33.5913H210.754C207.354 33.5913 204.706 31.8987 204.706 28.3181C204.706 24.6074 207.812 22.9473 211.081 22.9473C212.487 22.9473 213.861 23.2077 215.136 23.761ZM211.343 24.998C209.25 24.998 207.289 25.8768 207.289 28.3181C207.289 30.499 208.825 31.3453 210.82 31.3779H211.539C212.03 31.3779 212.487 31.3779 212.978 31.3453C213.73 31.3128 214.449 31.2151 215.136 31.0524V25.649C214.089 25.2909 212.749 24.998 211.343 24.998Z" fill="#00036E" />
            <path d="M226 31.15V33.5913C222.305 33.5913 220.245 31.5732 220.245 28.0903V10.513H222.861V28.0903C222.861 29.9456 223.809 31.15 226 31.15Z" fill="#00036E" />
            <path d="M18.6698 5.16562C16.9812 5.76719 12.8987 7.24736 9.51903 8.67738C8.77717 8.99128 7.66065 9.41373 6.90793 9.68828C3.21634 11.0348 0.084243 12.162 0.084243 17.2702L0 20.4714L12.0464 15.5427V21.3192C21.0354 14.0678 18.7862 5.13916 18.7862 5.13916L18.6698 5.16562Z" fill="url(#paint0_linear_246_1471)" />
            <path d="M12.0464 21.7729C15.7218 19.4992 18.0279 17.133 19.4598 14.8931V27.5478C19.4598 35.5023 15.1786 36.697 12.5026 37.4438C12.3445 37.4879 12.192 37.5304 12.0464 37.5727V21.7729Z" fill="url(#paint1_linear_246_1471)" />
            <path d="M16.5959 5.98125L18.4493 5.56004C18.4493 5.56004 21.2293 16.0062 8.25586 23.5038C22.3244 16.5959 21.5663 8.17157 21.2293 4.97034L22.7457 4.63337L18.6178 0L16.5959 5.98125Z" fill="url(#paint2_linear_246_1471)" />
            <defs>
               <linearGradient id="paint0_linear_246_1471" x1="9.72988" y1="4.88643" x2="9.72988" y2="37.5727" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#003CB3" />
                  <stop offset="1" stopColor="#02195F" />
               </linearGradient>
               <linearGradient id="paint1_linear_246_1471" x1="9.72988" y1="4.88643" x2="9.72988" y2="37.5727" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#003CB3" />
                  <stop offset="1" stopColor="#02195F" />
               </linearGradient>
               <linearGradient id="paint2_linear_246_1471" x1="18.7862" y1="-0.336972" x2="19.3759" y2="8.67703" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#04B50E" />
                  <stop offset="1" stopColor="#148313" />
               </linearGradient>
            </defs>
         </svg>

      </SVGContainer>
   );
}

export default LogoIcon;