// AUTH
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const STORE_STEP_DATA = 'STORE_STEP_DATA';
export const CHECK_AUTH = 'CHECK_AUTH';
export const SET_USER_DATA = 'SET_USER_DATA';
export const STORE_STEP_COUNT = 'STORE_STEP_COUNT';
export const CLEAR_STEP_DATA = 'CLEAR_STEP_DATA';

// UI
export const API_CALL_PROGRESS = 'API_CALL_PROGRESS';