import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

function PlanIcon({
  width = 138,
  height = 138,
  color: iconColor,
  ...restProps
}) {
  const theme = useTheme();
  const color = iconColor || theme.colors.color.primary;
  return (
    <SVGContainer {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        color={color}
        viewBox="0 0 96 75"
        fill="none"
      >
        <g clipPath="url(#clip0_1266_1419)">
          <path
            d="M47.9827 75C32.9208 75 17.8573 75 2.79537 75C0.48393 75 0 74.5116 0 72.1775C0 49.0507 0 25.9223 0 2.79542C0 0.512494 0.521502 0 2.84797 0C32.9403 0 63.0311 0 93.1235 0C95.468 0 95.997 0.533535 95.997 2.89912C95.997 25.9599 95.997 49.0206 95.997 72.0813C95.997 72.6134 96.0256 73.1454 95.8602 73.6669C95.6243 74.4079 95.1268 74.8497 94.3679 74.9549C93.941 75.0135 93.5037 74.9985 93.0709 74.9985C78.042 75 63.0116 75 47.9827 75ZM47.9797 16.1578C33.6151 16.1578 19.2505 16.1654 4.88589 16.1383C4.00069 16.1368 3.71213 16.3277 3.71364 17.27C3.74219 34.8676 3.74219 52.4653 3.71364 70.0629C3.71213 71.0143 3.94057 71.2893 4.92046 71.2878C33.6497 71.2607 62.3774 71.2623 91.1066 71.2848C92.0189 71.2848 92.2533 71.0443 92.2518 70.1306C92.2232 52.4999 92.2247 34.8691 92.2518 17.2384C92.2518 16.3803 92.0775 16.1338 91.1727 16.1353C76.7751 16.1684 62.3774 16.1578 47.9797 16.1578ZM47.9241 3.75729C33.5625 3.75729 19.1994 3.7648 4.8378 3.73925C3.99017 3.73775 3.68208 3.90458 3.71213 4.81985C3.78427 6.94948 3.79028 9.08663 3.71063 11.2163C3.67306 12.2052 3.98566 12.4096 4.93098 12.4081C20.1928 12.3765 35.4546 12.387 50.7165 12.387C64.2124 12.387 77.7083 12.3795 91.2028 12.4066C92.0234 12.4081 92.2773 12.2202 92.2518 11.3741C92.1872 9.20987 92.1812 7.04116 92.2533 4.87696C92.2834 3.95417 92.0128 3.73775 91.1081 3.73925C76.7134 3.76931 62.3173 3.7603 47.9226 3.7603L47.9241 3.75729Z"
            fill="currentColor"
          />
          <path
            d="M84.04 32.5562C84.0475 37.4677 80.0138 41.5301 75.0948 41.5632C70.1924 41.5962 66.0038 37.4076 66.0594 32.5276C66.1151 27.6101 70.1894 23.5627 75.0723 23.5733C79.9837 23.5838 84.031 27.6386 84.0385 32.5562H84.04ZM80.2813 32.5547C80.2738 29.6315 77.9293 27.32 74.9986 27.3456C72.1416 27.3696 69.8212 29.7202 69.8287 32.5817C69.8362 35.4839 72.2138 37.8239 75.1249 37.7938C77.9984 37.7638 80.2873 35.4373 80.2813 32.5547Z"
            fill="currentColor"
          />
          <path
            d="M39.0105 32.5698C39.009 27.6462 43.0352 23.5989 47.9587 23.5733C52.8686 23.5493 57.0632 27.7514 56.9835 32.6149C56.9039 37.5505 52.8386 41.5828 47.9617 41.5632C43.0458 41.5437 39.012 37.4903 39.0105 32.5683V32.5698ZM47.9857 37.7939C50.8503 37.7939 53.1948 35.4734 53.2248 32.6149C53.2534 29.7248 50.8833 27.3427 47.9797 27.3442C45.1002 27.3457 42.7903 29.6361 42.7587 32.5217C42.7271 35.4313 45.0701 37.7939 47.9857 37.7939Z"
            fill="currentColor"
          />
          <path
            d="M29.888 32.6779C29.7798 37.645 25.6994 41.6473 20.8316 41.5616C15.9006 41.4744 11.8548 37.3564 11.939 32.5065C12.0246 27.5063 16.1681 23.4485 21.054 23.5777C26.0165 23.71 29.9947 27.8069 29.8895 32.6794L29.888 32.6779ZM20.9172 27.3425C18.0512 27.344 15.7112 29.654 15.6691 32.5216C15.6271 35.3966 18.0197 37.7968 20.9232 37.7953C23.7817 37.7953 26.1217 35.4838 26.1698 32.6147C26.2179 29.7532 23.8148 27.341 20.9157 27.3425H20.9172Z"
            fill="currentColor"
          />
          <path
            d="M20.8316 48.777C18.3368 48.777 15.842 48.789 13.3472 48.771C12.0547 48.762 11.2717 48.0917 11.2146 47.0156C11.168 46.1123 11.6624 45.3879 12.5416 45.155C12.9218 45.0543 13.3276 45.0167 13.7229 45.0167C18.5126 45.0077 23.3038 45.0107 28.0935 45.0122C28.358 45.0122 28.627 45.0122 28.8885 45.0543C30.0052 45.2316 30.6845 45.9921 30.6364 46.9931C30.5898 48.0045 29.8459 48.7244 28.7142 48.7635C27.551 48.8041 26.3862 48.7755 25.2215 48.777C23.7577 48.777 22.2939 48.777 20.83 48.777H20.8316Z"
            fill="currentColor"
          />
          <path
            d="M21.0374 52.5675C23.5322 52.5675 26.027 52.5524 28.5218 52.5735C29.7933 52.584 30.5913 53.2919 30.6364 54.3649C30.683 55.4606 29.8639 56.2496 28.5834 56.3338C28.3189 56.3518 28.0514 56.3383 27.7854 56.3383C23.693 56.3383 19.6022 56.3383 15.5098 56.3383C14.7118 56.3383 13.9092 56.3804 13.1157 56.3187C11.7721 56.212 10.9966 55.2261 11.2461 54.0313C11.4355 53.128 12.1899 52.5795 13.3517 52.5735C15.9126 52.5584 18.475 52.569 21.0359 52.569L21.0374 52.5675Z"
            fill="currentColor"
          />
          <path
            d="M47.9316 52.5676C50.4925 52.5676 53.0534 52.5676 55.6159 52.5676C56.486 52.5676 57.1999 52.8922 57.5471 53.7173C57.8176 54.3606 57.7485 55.0203 57.2931 55.599C56.8648 56.1445 56.2606 56.3279 55.6144 56.3294C50.5256 56.3414 45.4353 56.3519 40.3465 56.3249C39.0585 56.3189 38.2154 55.4757 38.25 54.3951C38.2846 53.2965 39.1217 52.5796 40.4472 52.5706C42.942 52.5556 45.4368 52.5661 47.9316 52.5661V52.5676Z"
            fill="currentColor"
          />
          <path
            d="M75.0873 52.5675C77.6151 52.5675 80.1445 52.5465 82.6724 52.575C84.2895 52.5931 85.2273 53.9232 84.5991 55.2382C84.2444 55.9822 83.5906 56.3188 82.8227 56.3218C77.6662 56.3429 72.5098 56.3519 67.3534 56.3203C66.1135 56.3128 65.3275 55.4877 65.3411 54.4221C65.3546 53.313 66.1782 52.5826 67.5007 52.572C70.0286 52.554 72.5564 52.566 75.0858 52.5675H75.0873Z"
            fill="currentColor"
          />
          <path
            d="M47.9377 48.7756C45.3767 48.7756 42.8143 48.7906 40.2534 48.7666C39.1818 48.756 38.483 48.1924 38.3011 47.2561C38.1358 46.4085 38.4785 45.7607 39.1954 45.3129C39.5696 45.0784 40.0054 45.0168 40.4397 45.0168C45.4624 45.0123 50.4851 45.0002 55.5077 45.0228C56.9039 45.0288 57.7124 45.7953 57.6884 46.9495C57.6644 48.1023 56.8513 48.7696 55.422 48.7756C52.9272 48.7846 50.4325 48.7786 47.9377 48.7771V48.7756Z"
            fill="currentColor"
          />
          <path
            d="M74.9911 48.777C72.5294 48.777 70.0676 48.7845 67.6059 48.774C66.1977 48.768 65.3636 48.0752 65.3456 46.9329C65.3275 45.7967 66.1586 45.0272 67.5503 45.0212C72.573 45.0002 77.5971 44.9987 82.6198 45.0212C83.9318 45.0272 84.7824 45.8088 84.802 46.8773C84.8215 48 83.9453 48.7635 82.5747 48.771C80.0468 48.7845 77.5175 48.7755 74.9896 48.7755L74.9911 48.777Z"
            fill="currentColor"
          />
          <path
            d="M75.0843 60.1256C77.6452 60.1256 80.2061 60.0986 82.767 60.1362C84.288 60.1587 85.1717 61.4031 84.6366 62.6791C84.3586 63.3434 83.8597 63.7612 83.1217 63.7958C82.3252 63.8348 81.5272 63.8423 80.7291 63.8423C76.5391 63.8469 72.3475 63.8454 68.1575 63.8423C67.7592 63.8423 67.3564 63.8423 66.9642 63.7882C65.9678 63.653 65.3381 62.9226 65.3486 61.9607C65.3591 61.0124 66.0219 60.2203 67.0048 60.1692C68.3979 60.0971 69.7971 60.1286 71.1933 60.1226C72.4903 60.1166 73.7873 60.1226 75.0843 60.1226V60.1271V60.1256Z"
            fill="currentColor"
          />
          <path
            d="M20.824 60.1227C23.2527 60.1227 25.6798 60.1181 28.1085 60.1227C28.4737 60.1227 28.8464 60.1211 29.2011 60.1903C30.0457 60.3571 30.6409 61.1281 30.6379 61.9863C30.6348 62.858 30.0472 63.6004 29.1891 63.7627C28.8329 63.8303 28.4617 63.8394 28.098 63.8409C23.3083 63.8454 18.5186 63.8439 13.7304 63.8439C13.5635 63.8439 13.3967 63.8484 13.2314 63.8379C11.9509 63.7627 11.21 63.0759 11.213 61.9757C11.216 60.8801 11.981 60.1437 13.2434 60.1302C15.7713 60.1031 18.2991 60.1227 20.827 60.1211L20.824 60.1227Z"
            fill="currentColor"
          />
          <path
            d="M48.0248 60.1227C50.5526 60.1227 53.0805 60.1091 55.6068 60.1302C56.8197 60.1407 57.5396 60.7343 57.6628 61.7278C57.7996 62.8354 57.1578 63.6906 56.0156 63.7913C55.1244 63.8694 54.2227 63.8424 53.3255 63.8439C49.2346 63.8484 45.1437 63.8469 41.0544 63.8439C40.7222 63.8439 40.3886 63.8484 40.058 63.8168C38.9669 63.7086 38.2229 62.9271 38.25 61.9397C38.2785 60.9342 39.027 60.1813 40.1451 60.1422C41.4406 60.0971 42.7391 60.1242 44.0361 60.1227C45.3662 60.1212 46.6962 60.1227 48.0263 60.1227H48.0248Z"
            fill="currentColor"
          />
          <path
            d="M24.5076 6.34236C26.9679 6.34236 29.4296 6.34537 31.8898 6.34236C32.7374 6.34236 33.3987 6.69254 33.7324 7.46354C34.0089 8.10077 33.9382 8.76055 33.4889 9.34669C33.0876 9.8697 32.5255 10.0621 31.9139 10.0636C26.9919 10.0756 22.0714 10.0936 17.1495 10.0516C15.8886 10.041 15.1672 9.22045 15.2123 8.1143C15.2558 7.06376 16.0133 6.3619 17.2246 6.34988C19.6518 6.32433 22.079 6.34236 24.5076 6.34236Z"
            fill="currentColor"
          />
          <path
            d="M9.56288 10.0364C8.56045 10.0424 7.70681 9.22781 7.68126 8.23739C7.65421 7.22292 8.54993 6.3377 9.58392 6.35574C10.5788 6.37377 11.4204 7.22442 11.4129 8.20883C11.4069 9.19475 10.5608 10.0304 9.56288 10.0364Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1266_1419">
            <rect width="96" height="75" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGContainer>
  );
}

export default PlanIcon;
