const Styles = (theme) => {
    return ({
        'html': {
            fontSize: '14px',


            '@media(min-width: 1440px)': {
                fontSize: '16px',
            },

            '@media(min-width: 1921px)': {
                fontSize: '20px',
            }
        },

        'html,body': {
            padding: 0,
            margin: 0,
            boxSizing: 'border-box'
        },

        '*': {
            boxSizing: 'border-box',
            borderColor: theme.colors.borderColor.color1
        },

        'body': {
            backgroundColor: theme.colors.bodyBgColor,
            color: theme.colors.color.color1,
            fontFamily: `'Open Sans', sans-serif`,
            fontSize: '1rem'
        },

        '.color-1': {
            '&, *': {
                color: theme.colors.color.color1
            }
        },

        '.color-2': {
            '&, *': {
                color: theme.colors.color.color2
            }
        },

        '.color-3': {
            '&, *': {
                color: theme.colors.color.color3
            }
        },

        '.color-primary': {
            '&, *': {
                color: theme.colors.palatte.primary
            }
        },
        '.otpboxMainWrap': {
            display: 'grid !important',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
            gridGap: 20,
            '& .MuiInputBase-root': {
                height: '80px !important',
                'input': {
                    height: '100%',
                    textAlign: 'center',
                    fontSize: 40,
                    lineHeight: 0,
                    padding: 0,
                }
            }
        }
    })
}

export default Styles;