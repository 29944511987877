import { CHECK_AUTH, LOGGED_IN, LOGGED_OUT, SET_USER_DATA, STORE_STEP_DATA, STORE_STEP_COUNT, CLEAR_STEP_DATA } from "Redux/Constants";

export const setLogin = (data) => ({
    type: LOGGED_IN,
    payload: data
})

export const setStepData = (data) => ({
    type: STORE_STEP_DATA,
    payload: data
})

export const setLogout = () => ({
    type: LOGGED_OUT,
})

export const setCheckAuth = () => ({
    type: CHECK_AUTH,
})

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data
})

export const setStepCount = (data) => ({
    type: STORE_STEP_COUNT,
    payload: data
})

export const setClearStepData = () => ({
    type: CLEAR_STEP_DATA,
})