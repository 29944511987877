import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

function PriceIcon({
  width = 66,
  height = 66,
  color: iconColor,
  ...restProps
}) {
  const theme = useTheme();
  const color = iconColor || theme.colors.color.primary;
  return (
    <SVGContainer {...restProps}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_107_1382)">
          <path
            d="M33.0002 66C22.1652 66 11.3577 66 0.522656 66C0.110156 66 -0.0273438 65.945 -0.0273438 65.4775C0.00015625 43.835 0.00015625 22.165 0.00015625 0.495C0.00015625 0.0825 0.110156 0 0.495156 0C22.1652 0 43.8352 0 65.4777 0C65.8352 0 65.9727 0.0275 65.9727 0.4675C65.9727 22.165 65.9727 43.835 65.9727 65.5325C65.9727 66 65.7802 66 65.4502 65.9725C54.6152 65.9725 43.8077 65.9725 32.9727 65.9725L33.0002 66ZM32.9452 2.1725C15.9777 2.0625 1.95266 16.005 2.00766 33.165C2.06266 50.0775 15.8402 63.8825 32.6977 63.9925C49.9127 64.13 63.8827 50.0775 63.8552 33C63.8552 16.0325 49.8852 2.035 32.9452 2.145V2.1725Z"
            fill="currentColor"
          />
          <path
            d="M32.945 2.14499C49.885 2.03499 63.8275 16.0325 63.855 33C63.855 50.0775 49.885 64.13 32.6975 63.9925C15.84 63.855 2.06249 50.0775 2.00749 33.165C1.95249 16.005 15.9775 2.06249 32.945 2.14499ZM6.24249 33.055C6.40749 47.9325 18.0675 59.73 32.9175 59.7575C47.8775 59.7575 59.675 47.85 59.62 33C59.565 18.1225 47.7675 6.40749 32.945 6.40749C18.0675 6.40749 6.40749 18.205 6.24249 33.055Z"
            fill="currentColor"
          />
          <path
            d="M32.9173 16.555C35.6948 16.555 38.4448 16.555 41.2223 16.555C42.4048 16.555 43.2848 17.4625 43.2848 18.645C43.2848 19.8275 42.4323 20.7075 41.2223 20.735C40.8098 20.735 40.3973 20.735 40.0123 20.735C39.2423 20.735 38.4723 20.735 37.7023 20.735C37.3448 20.735 37.2898 20.845 37.4823 21.1475C38.1148 22.11 38.5823 23.1825 38.8573 24.31C38.9398 24.695 39.1598 24.7775 39.5173 24.7775C40.0948 24.7775 40.6723 24.7775 41.2498 24.7775C42.4323 24.805 43.3123 25.7125 43.2848 26.895C43.2848 28.0775 42.4048 28.9575 41.2223 28.985C40.6998 28.985 40.1773 29.0125 39.6823 28.985C39.1598 28.93 38.9398 29.15 38.8023 29.645C38.1148 32.0925 36.7123 34.045 34.6223 35.475C33.6873 36.135 32.6423 36.575 31.5423 36.8775C31.3773 36.9325 31.1298 36.8775 31.1023 37.07C31.0473 37.3175 31.2948 37.3725 31.4323 37.4825C35.0073 40.15 38.5548 42.845 42.1298 45.5125C42.7073 45.925 43.1748 46.3925 43.2848 47.135C43.4223 48.015 43.0373 48.8675 42.3223 49.28C41.4973 49.7475 40.6723 49.72 39.9023 49.1425C38.5273 48.125 37.1523 47.08 35.7773 46.0625C31.7623 43.065 27.7473 40.04 23.7323 37.0425C23.0998 36.575 22.6323 36.025 22.6323 35.2C22.6323 33.9625 23.4573 33.055 24.6948 33.0275C26.0148 33.0275 27.3073 33.0275 28.6273 33.0275C31.1298 32.9725 33.0823 31.9275 34.2923 29.6725C34.6223 29.04 34.6223 29.0125 33.8798 29.0125C30.8273 29.0125 27.7473 29.0125 24.6948 29.0125C23.6498 29.0125 22.7698 28.215 22.6598 27.225C22.5223 26.125 23.1273 25.1625 24.1448 24.915C24.4198 24.8325 24.7498 24.8325 25.0248 24.8325C27.9948 24.8325 30.9373 24.8325 33.9073 24.8325C34.5948 24.8325 34.5948 24.8325 34.2923 24.2C33.0823 21.89 31.1298 20.8725 28.5998 20.79C27.3348 20.7625 26.0698 20.79 24.8048 20.79C23.5123 20.79 22.6323 19.8825 22.6598 18.645C22.6598 17.435 23.5398 16.5825 24.8048 16.5825C27.5273 16.5825 30.2498 16.5825 32.9723 16.5825L32.9173 16.555Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_107_1382">
            <rect width="66" height="66" fill="red" />
          </clipPath>
        </defs>
      </svg>
    </SVGContainer>
  );
}

export default PriceIcon;
