import React from 'react'
import LoadingImage from '../../../Assets/loader.gif';

export default function Loader() {
    return (
        <div className='appLoader'>
            <img src={LoadingImage} alt='appLoading' />
        </div>
    )
}
