export const storage = process.env.NODE_ENV === 'development' ? sessionStorage : localStorage;
export const stateName = `${window.location.origin}:state`


export default class Storage {
    static LoadState = () => {
        try {
            const state = storage.getItem(stateName);

            if (!state || state === null) {
                return this.initializeState();
            }
            return JSON.parse(state);

        } catch (error) {
            return error;
        }
    }

    static SaveState = (localState) => {
        try {
            if (!localState || localState === null) {
                return this.initializeState();
            }
            storage.clear();
            const state = JSON.stringify(localState);
            storage.setItem(stateName, state);

        } catch (error) {
            return error;
        }
    }

    static initializeState = () => {
        return {};
    }
}