import { Config } from "../Shared";
import BaseService from "./BaseService";
import Http from "../Http";

export default class AuthService extends BaseService {
  static register(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post("/register", params)
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static login(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post("/login", params)
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static logout() {
    return new Promise((resolve, reject) => {
      Http.post("/logout")
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static forgotPassword(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post("/forget-password", params)
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static mPinVerify(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post("/mpin-varification", params)
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static resetPassword(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post("/reset-password", params)
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static getMe() {
    return new Promise((resolve, reject) => {
      Http.get("/user/profile")
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static updateProfile(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post("/user/profile-update", params)
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static changePassword(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post("/user/change-password", params)
        .then((resp) => {
          return resolve(resp.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  static checkEmailAvailability(email = '') {
    return new Promise((resolve, reject) => {
      Http.get(`/check-email-availability?email=${email}`)
        .then((resp) => {
          return resolve(resp.data);
        }).catch((err) => {
          return reject(err);
        })
    })
  }

  static checkPhoneAvailability(phone = '') {
    return new Promise((resolve, reject) => {
      Http.get(`/check-phone-availability?phone=${phone}`)
        .then((resp) => {
          return resolve(resp.data);
        }).catch((err) => {
          return reject(err);
        })
    })
  }

  static verifyEmail(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post('/email-verification', params)
        .then((resp) => {
          return resolve(resp.data);
        }).catch((err) => {
          return reject(err);
        })
    })
  }

  static resendVerifyEmail(params = {}) {
    return new Promise((resolve, reject) => {
      Http.post('email-verification?status=resend', params)
        .then((resp) => {
          return resolve(resp.data);
        }).catch((err) => {
          return reject(err);
        })
    })
  }
}
