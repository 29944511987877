import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function SortIcon({ width = 9, height = 15, color: iconColor, ...restProps }) {
    const theme = useTheme();
    const color = iconColor || theme.colors.color.primary;
    return (
        <SVGContainer {...restProps}>
            <svg width={width} height={height} color={color} viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 0L8.39711 6.75H0.602886L4.5 0Z" fill="currentColor" />
                <path d="M4.50052 14.0674L0.706317 7.25905L8.49965 7.37737L4.50052 14.0674Z" fill="currentColor" />
            </svg>
        </SVGContainer>
    );
}

export default SortIcon;
