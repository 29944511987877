import Http from "Http";
import { CHECK_AUTH, CLEAR_STEP_DATA, LOGGED_IN, LOGGED_OUT, SET_USER_DATA, STORE_STEP_COUNT, STORE_STEP_DATA } from "../Constants";

const initialState = {
    token: null,
    isAuthenticated: false,
    user: { email: '', name: '' },
    rememberMe: false,
    lastLoggedIn: null,
    stepCount: null
}

const Auth = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOGGED_IN:
            Http.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
            return {
                ...state,
                token: payload.token,
                user: payload.user,
                isAuthenticated: payload.isAuthenticated,
                lastLoggedIn: new Date().toISOString()
            };

        case LOGGED_OUT:
            Http.defaults.headers.common['Authorization'] = '';
            return {
                ...state,
                user: initialState.user,
                token: initialState.token,
                isAuthenticated: initialState.isAuthenticated,
                lastLoggedIn: initialState.lastLoggedIn
            }

        case STORE_STEP_DATA:
            return {
                ...state,
                user: { ...state.user, ...payload }
            }

        case CLEAR_STEP_DATA:
            return {
                ...state,
                user: initialState.user
            }

        case CHECK_AUTH:
            if (state.token && state.isAuthenticated) {
                Http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
            }

            return state;

        case SET_USER_DATA:
            return {
                ...state,
                user: { ...state.user, ...payload }
            }

        case STORE_STEP_COUNT:
            return {
                ...state,
                stepCount: payload
            }

        default:
            return state;
    }
}

export default Auth;