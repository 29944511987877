import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

function TickCircleIcon({
  width = 22,
  height = 22,
  color: iconColor,
  ...restProps
}) {
  const theme = useTheme();
  const color = iconColor || theme.colors.color.primary;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.78 7.35583L9.13251 12.0142L7.345 10.2267C7.24789 10.1133 7.12838 10.0212 6.99397 9.95613C6.85957 9.8911 6.71318 9.85456 6.56398 9.8488C6.41478 9.84304 6.26601 9.86818 6.12699 9.92264C5.98797 9.97711 5.86171 10.0597 5.75613 10.1653C5.65056 10.2709 5.56795 10.3971 5.51348 10.5361C5.45902 10.6752 5.43388 10.8239 5.43964 10.9731C5.4454 11.1223 5.48194 11.2687 5.54697 11.4031C5.61199 11.5375 5.7041 11.657 5.8175 11.7542L8.36334 14.3108C8.46457 14.4112 8.58462 14.4907 8.71661 14.5446C8.8486 14.5985 8.98993 14.6258 9.13251 14.625C9.41671 14.6238 9.68906 14.511 9.89084 14.3108L15.3075 8.89417C15.409 8.79346 15.4896 8.67364 15.5446 8.54162C15.5996 8.40961 15.628 8.26801 15.628 8.125C15.628 7.98199 15.5996 7.84039 15.5446 7.70837C15.4896 7.57636 15.409 7.45654 15.3075 7.35583C15.1045 7.15406 14.83 7.04081 14.5438 7.04081C14.2576 7.04081 13.983 7.15406 13.78 7.35583ZM10.8333 0C8.69071 0 6.59619 0.635364 4.81466 1.82575C3.03313 3.01613 1.64459 4.70806 0.824643 6.6876C0.00469393 8.66713 -0.209842 10.8454 0.208164 12.9468C0.626171 15.0483 1.65795 16.9786 3.17301 18.4937C4.68808 20.0087 6.6184 21.0405 8.71986 21.4585C10.8213 21.8765 12.9995 21.662 14.9791 20.842C16.9586 20.0221 18.6505 18.6335 19.8409 16.852C21.0313 15.0705 21.6667 12.976 21.6667 10.8333C21.6667 9.41068 21.3865 8.00196 20.842 6.6876C20.2976 5.37323 19.4996 4.17898 18.4937 3.17301C17.4877 2.16704 16.2934 1.36906 14.9791 0.824638C13.6647 0.280212 12.256 0 10.8333 0ZM10.8333 19.5C9.11923 19.5 7.44362 18.9917 6.0184 18.0394C4.59317 17.0871 3.48234 15.7335 2.82638 14.1499C2.17042 12.5663 1.99879 10.8237 2.3332 9.14255C2.6676 7.46138 3.49302 5.91713 4.70508 4.70507C5.91713 3.49302 7.46139 2.6676 9.14256 2.33319C10.8237 1.99879 12.5663 2.17042 14.1499 2.82638C15.7336 3.48234 17.0871 4.59316 18.0394 6.01839C18.9917 7.44362 19.5 9.11923 19.5 10.8333C19.5 13.1319 18.5869 15.3363 16.9616 16.9616C15.3363 18.5869 13.1319 19.5 10.8333 19.5Z"
          fill="currentColor"
        />
      </svg>
    </SVGContainer>
  );
}

export default TickCircleIcon;
