import axios from 'axios';
import { ToastService } from './Shared/Alert';
import { Config } from 'Shared';
import Store from 'Redux/Store';
import { setLogout } from 'Redux/Actions/Auth';
import { setApiCallProgress } from 'Redux/Actions/Ui';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'DELETE, POST, GET, OPTIONS';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, Authorization, X-Requested-With';

axios.defaults.baseURL = Config.API_URL;

axios.interceptors.request.use(
    (config) => {
        const nonLoadingUrls = ['/check-email-availability', '/check-phone-availability', '/trading', '/get-instruments','/market-indexes'];
        if (!nonLoadingUrls.some(url => config.url.includes(url))) {
            Store.dispatch(setApiCallProgress(true));
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

axios.interceptors.response.use(
    (resp) => {
        Store.dispatch(setApiCallProgress(false));
        if ((resp?.status === 200 || resp?.status === 201) && resp?.data?.message) {
            if (resp.data.message !== 'OK') {
                ToastService.success(resp.data.message);
            }
        }
        return resp;
    },
    (error) => {
        Store.dispatch(setApiCallProgress(false));
        if (error && error.response) {
            const { data, status } = error.response;
            const errorStatuses = [400, 403, 404, 429, 422];
            if (status === 500) {
                ToastService.error(data.message);
            } else if ((status === 401 || status === 421) && data.message) {
                Store.dispatch(setLogout());
                ToastService.error(data.message);
            } else if (errorStatuses.includes(status) && data.message) {
                ToastService.warning(data.message);
            }
            return Promise.reject(data);
        }
    }
);

const Http = axios;

export default Http;
