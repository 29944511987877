import React, { lazy, Suspense, useState } from 'react';
import { HashRouter, Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from 'Components';

const AuthLayout = lazy(() => import('../Layouts/AuthLayout/AuthLayout'));
const DashboardLayout = lazy(() => import('../Layouts/DashboardLayout/DashboardLayout'));
const Login = lazy(() => import('../Pages/Auth/Login/LoginView'));
const SignupView = lazy(() => import('../Pages/Auth/Signup/SignupView'));
const ForgorPasswordView = lazy(() => import('../Pages/Auth/ForgotPassword/ForgorPasswordView'));
const ChartView = lazy(() => import('../Pages/Public/ChartView'));
const PlansListView = lazy(() => import('../Pages/Authorised/Virtualtrading/Subscription/PlansListView'));
const VTdashboard = lazy(() => import('../Pages/Authorised/Virtualtrading/VTdashboard/Dashboard'));
const Portfolio = lazy(() => import('../Pages/Authorised/Virtualtrading/Portfolio/Portfolio'));
const LeaguePortfolio = lazy(() => import('../Pages/Authorised/Leagues/Portfolio/Portfolio'));
const Leaderboard = lazy(() => import('../Pages/Authorised/Virtualtrading/Leaderboard/Leaderboard'));
const TradingPanel = lazy(() => import('../Pages/Authorised/Tradingpanel/TradingPanel/TradingPanel'));
const Trade = lazy(() => import('../Pages/Authorised/Tradingpanel/Trade/Trade'));
const LeagueDashboardView = lazy(() => import('../Pages/Authorised/Leagues/MyLeagues/LeagueDashboardView'));
const Settings = lazy(() => import('../Pages/Authorised/Settings'));
const UpcomingLeaguesView = lazy(() => import('../Pages/Authorised/Leagues/Upcoming/UpcomingLeaguesView'));
const LiveLeaguesView = lazy(() => import('../Pages/Authorised/Leagues/Live/LiveLeaguesView'));
const LeagueDetails = lazy(() => import('../Pages/Authorised/Leagues/LeagueDetails'));
const JoinLeague = lazy(() => import('../Pages/Authorised/Leagues/JoinLeague'));
const CompletedLeaguesView = lazy(() => import('../Pages/Authorised/Leagues/Completed/CompletedLeaguesView'));
const VirtualTradingPortfolioList = lazy(() => import('../Pages/Authorised/Virtualtrading/Portfolio/PortfolioDetailList'));
const LeaguePortfolioList = lazy(() => import('../Pages/Authorised/Leagues/Portfolio/PortfolioDetailList'));
const LeagueLeaderBoardListView = lazy(() => import('../Pages/Authorised/Leagues/LeaderBoard/LeagueLeaderBoradView'));
const NotFoundView = lazy(() => import('../Pages/NotFound/NotFoundView'));
const EmailVerifyView = lazy(() => import('../Pages/Auth/EmailVerify/EmailVerifyView'));
const OrderBook = lazy(() => import('../Pages/Authorised/Leagues/OrderBook'));
const PlReport = lazy(() => import('../Pages/Authorised/Reports/PlReport/index.jsx'));

function RequireAuth({ isAuthenticated }) {
  let location = useLocation();


  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <DashboardLayout />
    </Suspense>
  );
}


function StrictlyNotRequireAuth({ isAuthenticated }) {
  const withoutLayout = ['/chart-view'];

  let location = useLocation();
  const user = useSelector((state) => state.login.user);


  if (isAuthenticated) {
    if (!user?.is_vt_subscribe) {
      return <Navigate to="/plans" state={{ from: location }} />;
    } else {
      return <Navigate to="/vt-dashboard?login=true" state={{ from: location }} />;
    }
  }


  if (withoutLayout.includes(location.pathname)) {
    return <Outlet />
  }


  return (
    <Suspense fallback={<Loader />}>
      <AuthLayout />
    </Suspense>
  );
}

function RoutesComponent() {
  const { isAuthenticated } = useSelector((state) => state.login);

  return (
    <HashRouter>
      <Routes>
        <Route element={<StrictlyNotRequireAuth isAuthenticated={isAuthenticated} />}>
          <Route path="/login" exact={true} name='Login' element={<Suspense fallback={<Loader />}><Login /></Suspense>} />
          <Route path="/signup" exact={true} name='Signup' element={<Suspense fallback={<Loader />}><SignupView /></Suspense>} />
          <Route path="/forgot-password" exact={true} name='Forget Password' element={<Suspense fallback={<Loader />}><ForgorPasswordView /></Suspense>} />
          <Route path="/chart-view" exact={true} name='Chart View' element={<Suspense fallback={<Loader />}><ChartView /></Suspense>} />
          <Route path="/verify-email" exact={true} name='Email verification' element={<Suspense fallback={<Loader />}><EmailVerifyView /></Suspense>} />
        </Route>

        <Route element={<RequireAuth isAuthenticated={isAuthenticated} />}>
          {/* <Route path="/league-dashboard" exact={true} name='DashboardHome' element={<Suspense fallback={<Loader />}><LeagueDashboardView /></Suspense>} /> */}
          <Route path="/league-dashboard" exact={true} name='My Leagues' element={<Suspense fallback={<Loader />}><LeagueDashboardView /></Suspense>} />
          <Route path="/league/:id" exact={true} name='Leagues' element={<Suspense fallback={<Loader />}><JoinLeague /></Suspense>} />
          <Route path="/upcoming-leagues" exact={true} name='Upcoming Leagues' element={<Suspense fallback={<Loader />}><UpcomingLeaguesView /></Suspense>} />
          <Route path="/live-leagues" exact={true} name='Upcoming Leagues' element={<Suspense fallback={<Loader />}><LiveLeaguesView /></Suspense>} />
          <Route path="/completed-leagues" exact={true} name='Completed Leagues' element={<Suspense fallback={<Loader />}><CompletedLeaguesView /></Suspense>} />
          <Route path="/plans" exact={true} name='Plans' element={<Suspense fallback={<Loader />}><PlansListView /></Suspense>} />
          <Route path="/vt-dashboard" exact={true} name='VTdashboard' element={<Suspense fallback={<Loader />}><VTdashboard /></Suspense>} />
          <Route path="/league-details/:id" exact={true} name='VTdashboard' element={<Suspense fallback={<Loader />}><LeagueDetails /></Suspense>} />
          {/* league portfolio */}
          <Route path="/portfolio" exact={true} name='Portfolio' element={<Suspense fallback={<Loader />}><LeaguePortfolio /></Suspense>} />
          <Route path="/portfolio/:id" exact={true} name='DetailedPortfolio' element={<Suspense fallback={<Loader />}><LeaguePortfolioList /></Suspense>} />
          {/* <Route path="/detailed-portfolio" exact={true} name='DetailedPortfolio' element={<Suspense fallback={<Loader />}><DetailedPortfolio /></Suspense>} /> */}
          {/* VT portfolio */}
          <Route path="/vt-portfolio" exact={true} name='VTPortfolio' element={<Suspense fallback={<Loader />}><Portfolio /></Suspense>} />
          <Route path="/vt-portfolio/:id" exact={true} name='VTDetailedPortfolio' element={<Suspense fallback={<Loader />}><VirtualTradingPortfolioList /></Suspense>} />
          {/* <Route path="/vt-detailed-portfolio" exact={true} name='VtDetailedPortfolio' element={<Suspense fallback={<Loader />}><DetailedPortfolio /></Suspense>} /> */}
          <Route path="/leaderboard/portfolio/:id" exact={true} name='Portfolio' element={<Suspense fallback={<Loader />}><VirtualTradingPortfolioList /></Suspense>} />
          <Route path="/leaderboard" exact={true} name='Leaderboard' element={<Suspense fallback={<Loader />}><Leaderboard /></Suspense>} />
          <Route path="/trading-panel" exact={true} name='TradingPanel' element={<Suspense fallback={<Loader />}><TradingPanel /></Suspense>} />
          <Route path="/trade" exact={true} name='Trade' element={<Suspense fallback={<Loader />}><Trade /></Suspense>} />
          <Route path="/settings" exact={true} name='Settings' element={<Suspense fallback={<Loader />}><Settings /></Suspense>} />
          <Route path="/league/leaderboard/:id" exact={true} name='Settings' element={<Suspense fallback={<Loader />}><LeagueLeaderBoardListView /></Suspense>} />
          <Route path="/orders" exact={true} name='Group Book' element={<Suspense fallback={<Loader />}><OrderBook /></Suspense>} />
          <Route path="/pl-report" exact={true} name='PL Report' element={<Suspense fallback={<Loader />}><PlReport /></Suspense>} />
        </Route>

        <Route path="*" exact={true} name='NotFoundPage' element={<Suspense fallback={<Loader />}><NotFoundView /></Suspense>} />
        <Route path="" exact={true} name='RedirctToDashboard' element={<Navigate to="/league-dashboard" replace />} />
      </Routes>
    </HashRouter>
  )
}

export default RoutesComponent;
