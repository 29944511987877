import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function AngleIcon({ width = 20, height = 20, color: iconColor, ...restProps }) {
   const theme = useTheme();
   const color = iconColor || theme.colors.color.primary;
   return (
      <SVGContainer {...restProps}>
         <svg width={width} height={height} color={color} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6402 8.26824L10.6402 13.2682C9.61589 14.1218 8.33552 12.5854 9.35982 11.7318L15.3598 6.7318C16.3841 5.87822 17.6645 7.41466 16.6402 8.26824Z" fill="currentColor" />
            <path d="M4.64019 6.7318L10.6402 11.7318C11.6645 12.5854 10.3841 14.1218 9.35982 13.2682L3.35982 8.26824C2.33552 7.41466 3.61589 5.87822 4.64019 6.7318Z" fill="currentColor" />
         </svg>
      </SVGContainer>
   );
}

export default AngleIcon;