import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

function DateRangeIcon({
  width = 17,
  height = 15,
  color: iconColor,
  ...restProps
}) {
  const theme = useTheme();
  const color = iconColor || theme.colors.color.primary;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        color={color}
        fill="none"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 409.24 409.06"
      >
        <path
          className="cls-1"
          d="m409.24,191.89c-3.04,6.38-7.41,11.09-15,11.41-9.79.41-17.16-6.53-17.18-16.35-.07-29.14-.01-58.28-.04-87.42-.01-11-3.12-20.67-12-27.91-6.19-5.05-13.44-6.81-21.14-7.14-5.18-.22-10.38.04-15.57-.09-2.63-.07-3.54.98-3.46,3.54.13,3.99.07,7.98.01,11.97-.13,9.55-7.04,16.47-16.34,16.44-9.1-.03-15.97-6.94-16.12-16.26-.07-4.12-.1-8.25,0-12.37.06-2.38-.85-3.31-3.27-3.31-21.82.05-43.64.06-65.46,0-2.66,0-3.49,1.05-3.42,3.57.12,4.25.1,8.52,0,12.77-.24,8.89-7.37,15.65-16.35,15.61-8.75-.04-15.81-6.8-16.06-15.5-.12-4.39-.12-8.78-.02-13.17.06-2.42-.91-3.28-3.3-3.27-21.56.05-43.11.06-64.67-.01-2.7,0-3.45,1.13-3.39,3.6.11,4.25.11,8.52-.01,12.77-.24,8.88-7.4,15.63-16.39,15.57-8.74-.05-15.79-6.82-16.03-15.53-.12-4.25-.12-8.52-.01-12.77.06-2.43-.6-3.71-3.34-3.65-7.31.17-14.62-.22-21.91.78-12.47,1.72-24.09,13.76-25.46,26.3-.52,4.78-.67,9.54-.67,14.33.01,78.5,0,157.01,0,235.51,0,11.13,2.96,20.97,11.99,28.32,6.78,5.51,14.72,7.17,23.12,7.19,39.39.06,78.77,0,118.16.05,10.61.01,17.86,8.01,16.68,18.39-.79,6.95-5.35,11.01-11.34,13.8H55.53c-.37-.14-.73-.35-1.12-.41C23.67,404.17-.19,376.31,0,343.61c.48-81.96.16-163.92.16-245.87,0-4.12.18-8.21.78-12.32,3.77-25.96,26.71-48.91,52.6-52.71,8.36-1.23,16.73-.58,25.09-.77,5.36-.12,5.36-.03,5.37-5.57,0-3.46-.05-6.92.02-10.38C84.21,6.81,91.25-.05,100.4,0c8.91.05,15.88,6.91,16.07,15.9.09,4.26.11,8.52,0,12.77-.06,2.42.93,3.27,3.31,3.27,21.55-.05,43.11-.06,64.66.01,2.7,0,3.45-1.13,3.38-3.61-.12-4.12-.09-8.25,0-12.37C188,6.94,194.92.07,203.82,0c9.16-.07,16.25,6.77,16.44,15.93.09,4.12.11,8.25,0,12.37-.06,2.43.59,3.66,3.34,3.65,21.82-.07,43.64-.07,65.46,0,2.72,0,3.42-1.18,3.36-3.63-.11-4.25-.11-8.52.01-12.77C292.67,6.84,299.71.06,308.44,0c8.99-.06,16.16,6.68,16.41,15.55.12,4.12.22,8.26-.02,12.37-.19,3.26,1.13,4.14,4.19,4.05,5.85-.18,11.71-.09,17.56-.03,15.99.16,29.87,5.85,41.74,16.41,11.69,10.4,18.25,23.65,20.92,38.94v104.6Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m301.45,409.06c-11.57-1.98-22.88-4.82-33.29-10.48-29.17-15.87-47.06-40.1-51.44-73.03-6.81-51.16,25.45-96.56,75.73-108,49.31-11.23,101.44,20.69,113.8,69.72,1.17,4.63,2.01,9.34,3,14.01v22.36c-.22,1.03-.47,2.06-.65,3.1-5.82,33.18-23.59,57.54-53.63,72.91-9.82,5.03-20.4,7.51-31.15,9.41h-22.36Zm75.5-96.53c-.7-35.89-28.2-64.22-64.34-64.24-35.37-.02-63.88,27.46-64.14,63.87-.25,35.12,27.07,64.23,63.97,64.43,35.49.19,63.64-27.54,64.5-64.06Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m116.47,168.1c-.05,9.05-7.18,16.08-16.29,16.06-9.24-.02-16.28-7.25-16.16-16.59.11-9,7.33-15.95,16.49-15.86,9.02.08,16.01,7.26,15.96,16.39Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m185.94,168.11c-.05,9.05-7.19,16.07-16.31,16.05-9.23-.02-16.27-7.26-16.15-16.61.12-9,7.34-15.94,16.51-15.85,9.02.09,16.01,7.27,15.95,16.41Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m255.4,168.16c-.07,9.03-7.23,16.04-16.36,16-9.22-.04-16.24-7.3-16.1-16.65.13-8.99,7.37-15.9,16.55-15.8,9.01.1,15.97,7.3,15.9,16.46Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m324.86,168.2c-.08,9.02-7.26,16.01-16.39,15.96-9.21-.05-16.21-7.32-16.06-16.69.14-8.98,7.4-15.88,16.59-15.76,9,.11,15.95,7.33,15.86,16.49Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m100.08,253.63c-9.05-.05-16.09-7.18-16.07-16.29.02-9.24,7.25-16.28,16.59-16.17,9,.11,15.95,7.33,15.87,16.49-.08,9.02-7.26,16.02-16.39,15.97Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m185.94,237.54c-.04,9.06-7.17,16.1-16.27,16.09-9.25-.01-16.3-7.23-16.19-16.57.1-9.01,7.31-15.96,16.47-15.89,9.03.07,16.03,7.24,15.99,16.37Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m84.01,306.54c.1-9.01,7.3-15.98,16.45-15.91,9.2.07,16.17,7.36,16.01,16.75-.16,8.97-7.44,15.84-16.65,15.71-8.99-.13-15.91-7.37-15.81-16.55Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m153.48,306.55c.1-9.01,7.29-15.98,16.44-15.91,9.21.07,16.18,7.36,16.01,16.74-.16,8.97-7.44,15.84-16.64,15.71-8.99-.13-15.91-7.36-15.82-16.54Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m296.4,296c0-5.58-.09-11.16.02-16.74.18-8.93,7.24-15.76,16.15-15.77,8.94-.02,16.04,6.77,16.27,15.66.11,4.25.2,8.51-.02,12.75-.17,3.25.88,4.63,4.26,4.35,3.59-.29,7.13.02,10.44,1.84,6.5,3.59,9.93,11.15,8.13,18.3-1.79,7.09-7.99,12.17-15.33,12.27-8.1.11-16.21.14-24.31,0-8.56-.15-15.38-6.97-15.58-15.51-.13-5.71-.03-11.43-.03-17.14Z"
          fill="currentColor"
        />
      </svg>
    </SVGContainer>
  );
}

export default DateRangeIcon;
