import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

function RupeesIcon({
  width = 17,
  height = 15,
  color: iconColor,
  ...restProps
}) {
  const theme = useTheme();
  const color = iconColor || theme.colors.color.primary;
  return (
    <SVGContainer {...restProps}>
      <svg
        width={width}
        height={height}
        color={color}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 409.06 409.35"
      >
        <defs>
          <style></style>
        </defs>
        <path
          className="cls-1"
          d="m221.42,409.35h-33.53c-3.02-.42-6.04-.86-9.06-1.26-47.09-6.15-87.58-26.02-120.47-60.11C15.03,303.09-4.12,249.09.74,186.77c2.22-28.54,10.59-55.3,24.37-80.32C66.78,30.81,152.47-13.49,240.83,3.69c81.43,15.84,134.55,65.15,160.42,143.84,4.33,13.19,6.12,26.93,7.81,40.65v33.53c-.94,6.43-1.54,12.94-2.88,19.28-17.15,81.44-65.52,134.87-144.53,160.65-13.06,4.26-26.65,6-40.23,7.7Zm161.75-204.5c-.99-99.3-79.81-178.31-178.62-178.22-99.36.09-178.31,79.67-178.22,178.42.09,99.3,79.61,178.31,178.42,178.22,99.36-.09,177.6-79.67,178.42-178.42Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="m173.89,230.8c.58,2.47,2.15,3.39,3.32,4.57,24.34,24.39,48.7,48.76,73.07,73.12,3.07,3.07,5.69,6.31,5.77,10.98.09,5.29-2.11,9.31-6.67,11.86-4.71,2.63-9.47,2.34-13.93-.71-1.52-1.04-2.87-2.37-4.19-3.68-32.56-32.54-65.11-65.09-97.67-97.63-2.67-2.67-5.13-5.44-5.75-9.34-1.33-8.25,4.62-15.23,13.24-15.29,15.7-.1,31.4.03,47.1-.09,3.84-.03,7.74-.31,11.49-1.09,11.34-2.38,19.68-8.98,25.32-19.03,2.23-3.97,1.74-4.75-2.82-4.75-26.87-.01-53.75.01-80.62-.03-7.93-.01-13.57-5.17-13.82-12.42-.27-8,5.32-13.69,13.72-13.7,26.21-.05,52.42-.02,78.63-.02,1.06,0,2.13-.05,3.19,0,2.92.14,3.65-1.08,2.3-3.62-6.14-11.56-15.45-18.72-28.57-20.62-8.47-1.23-17-.59-25.5-.68-10.24-.1-20.49.05-30.73-.06-7.65-.08-13.1-5.7-13.06-13.17.05-7.25,5.47-12.7,12.9-12.94.4-.01.8-.01,1.2-.01,41.91,0,83.82-.01,125.73.01,8.19,0,13.86,5.17,14.04,12.61.18,7.53-5.17,13.34-12.72,13.49-7.31.15-14.63.1-21.95.03-2.58-.02-3.18.6-1.59,2.88,3.98,5.73,6.9,11.98,8.63,18.75.64,2.5,2.03,3.46,4.57,3.36,3.32-.13,6.65-.08,9.98,0,7.66.18,13.12,5.7,13.09,13.15-.04,7.41-5.55,12.81-13.27,12.97-2.79.06-5.61.25-8.38-.04-3.74-.38-5.32,1.12-6.39,4.72-5.96,19.96-18.68,34.02-37.95,41.98-7.06,2.91-14.45,4.52-22.14,4.46-6.45-.05-12.9-.01-19.56-.01Z"
          fill="currentColor"
        />
      </svg>
    </SVGContainer>
  );
}

export default RupeesIcon;
