import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const commonColor = {
    palatte: {
        primary: '#03A415',
        secondary: '#00036E',
        error: '#FF1E1E',
        warning: '#F9B959',
        white: '#ffffff',
        black: '#000000',
        success: '#37ad00',
        blue:'#0096ff',
        info:'#00036ec4'
    }
};

const dark = {
    ...commonColor,
    bodyBgColor: '#2c3145',
    componentBgColor: '#24262f',
    navbarBgColor: '#24262f',
    selectedBgColor: '#F3F9FF',

    color: {
        color1: '#FFFFFF',
        color2: '#b4a698',
        color3: '#646464',

    },
    borderColor: {
        color1: '#E2E2E2'
    },
    lightColor: {
        color1: '#E1E1E1'
    },
    textBgColor: {
        color1: '#EEEEEE'
    },
    invertColor: {}
};

const light = {
    ...commonColor,
    bodyBgColor: '#F8FAFC',
    componentBgColor: '#FFFFFF',
    navbarBgColor: '#FFFFFF',
    selectedBgColor: '#F3F9FF',
    tabBtnBgColor: '#E6ECE7',

    color: {
        color1: '#000000',
        color2: '#4B5967',
        color3: '#9B9B9B',
        color4: '#C3C3C3',
        color5: '#eee',
        active:'#03a415b5',
        appColor:'#00036E'

    },
    lightColor: {
        color1: '#E1E1E1'
    },
    borderColor: {
        color1: '#E2E2E2',
        color2: '#3a82e261',
        color3:'#3a82e21c'
    },
    textBgColor: {
        color1: '#EEEEEE',
    },
    invertColor: {}
};

const createPrimaryTheme = (type = 'light') => {
    const themeColors = type === 'light' ? { ...light } : { ...dark };
    let theme = createTheme({
        colors: { ...themeColors },
        palette: {
            primary: {
                main: themeColors.palatte.primary
            },
            secondary: {
                main: themeColors.palatte.secondary
            },
            error: {
                main: themeColors.palatte.error
            },
            success: {
                main: themeColors.palatte.primary
            },
            warning: {
                main: themeColors.palatte.warning
            },
            background: {
                paper: themeColors.componentBgColor
            },
            text: {
                primary: themeColors.color.color1,
                secondary: themeColors.color.color2
            }
        },
        typography: {
            fontFamily: `'Open Sans', sans-serif`,
            h1: {
                fontSize: '2rem',
                fontWeight: '600'
            },
            h2: {
                fontSize: '1.8rem',
                fontWeight: '600'
            },
            h3: {
                fontSize: '1.6rem',
                fontWeight: '600'
            },
            h4: {
                fontSize: '1.4rem',
                fontWeight: '600'
            },
            h5: {
                fontSize: '1.2rem',
                fontWeight: '600'
            },
            h6: {
                fontSize: '1rem',
                fontWeight: '600'
            }
        },
        components: {
            MuiButton: {
                defaultProps: {
                    variant: 'contained',
                },

                styleOverrides: {
                    root: {
                        fontSize: '1rem',
                        textTransform: 'capitalize',

                        '&&&': {
                            boxShadow: 'unset'
                        }
                    },

                    sizeLarge: {
                        height: '3.5rem'
                    }
                }
            },

            MuiInputBase: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            borderRadius: '0.8rem',
                            minHeight: '43px',
                        }
                    }
                }
            },

            MuiFormGroup: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            marginBottom: '1.4rem'
                        }
                    }
                }
            },

            MuiPopper: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            zIndex: 99999
                        }
                    }
                }
            },

            MuiLink: {
                defaultProps: {
                    underline: 'none'
                },
            },

            MuiCard: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            borderRadius: '1.4rem',
                            boxShadow: 'none',
                            border: `1px solid ${themeColors.borderColor.color1}`
                        }
                    }
                }
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
                xxl: 1400,
                sz_1600: 1600,
                sz_1800: 1800,
                sz_1900: 1900
            }
        }
    });

    theme = responsiveFontSizes(theme);
    return theme;

};

export default createPrimaryTheme;
