import React from 'react'
import PropTypes from 'prop-types'
import { Box, MenuItem, TextField, styled } from '@mui/material'
import { Map } from 'Helpers/Array';
import { GetValue } from 'Helpers/Object';

const PlaceHolder = styled('span')(({ }) => ({
    position: 'absolute',
    top: '27px',
    left: '10px',
    opacity: 0.5,
    right: 'auto',
    transform: 'translate(10px, -50%)'
}));

const MuiSelectListField = (props) => {
    const { fullWidth, items, valueField = 'value', titleField = 'label', placeHolder, ...restProps } = props;
    return (
        <Box sx={{ position: 'relative' }}>
            <TextField {...restProps} select fullWidth={fullWidth}>
                {items && items.length > 0 ? (
                    Map(items, (item, itemIndex) => (
                        <MenuItem
                            key={itemIndex}
                            value={GetValue(item, valueField)}
                        >
                            {GetValue(item, titleField)}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value="" sx={{ pointerEvents: 'none', color: '#000' }} disabled={true}>
                        No Data Found
                    </MenuItem>
                )}
            </TextField>
            {!props.value && placeHolder && <PlaceHolder>{placeHolder}</PlaceHolder>}
        </Box>
    )
}

MuiSelectListField.propTypes = {
    fullWidth: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    valueField: PropTypes.string.isRequired,
    titleField: PropTypes.string.isRequired,
    placeHolder: PropTypes.string.isRequired
}

MuiSelectListField.defaultProps = {
    fullWidth: true,
    items: [],
    valueField: 'value',
    titleField: 'label',
    placeHolder: 'Select value'
}

export default MuiSelectListField