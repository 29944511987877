import Config from "./Config";

export const getImageUrl = img => img && `${Config.API_URL}/${img}`;

function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

export const stringAvatar = (name) => {
    if (!name) return null;

    const splitName = name.split(' ');
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${splitName.length > 0 ? splitName[0][0] : ''}${splitName.length > 1 ? splitName[1][0] : ''}`,
    };
}

export const formatDate = (startDate, endDate) => {
    if (!startDate || !endDate) return;
    let start = new Date(startDate);
    let end = new Date(endDate);
    let finalDate = '';
    start = `${start.getDate()} ${start.toLocaleString('default', { month: 'short' })}`;
    end = `${end.getDate()} ${end.toLocaleString('default', { month: 'short' })}`;
    finalDate = `${start} - ${end}`;
    return finalDate;
}

export const formatDateWithYear = (date) => {
    if (!date) return;

    let newDate = new Date(date);
    newDate = `${newDate.getDate()} ${newDate.toLocaleString('default', { month: 'short' })} ${newDate.getFullYear()}`
    return String(newDate);
}

export const formatIntValue = (val) => {
    const IntVal = parseInt(parseFloat(val), 10);
    return IntVal.toString();
};

export const GetDate = (date) => {
    if (!date) {
        return '';
    }
    const utcDate = new Date(date);
    return utcDate.toISOString().slice(0, 10);
};

export const GetDateTime = (date) => {
    if (!date) {
        return 'N/A';
    }
    const utcDate = new Date(date);
    return utcDate.toISOString().slice(0, 10) + ' ' + utcDate.toLocaleTimeString();
};

export const isDateExpired = (date) => {
    if (!date) {
        return '';
    }
    const dateS = new Date(date);
    const diff = new Date().getTime() - dateS.getTime();

    if (diff > 0) {
        return true;
    }
    return false;
}

export const convertDateFormat = (date) => {
    // Create a Date object from the original string
    var originalDate = new Date(date);

    // Desired format: "YYYY-MM-DD HH:mm:ss"
    var options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    var desiredDateFormat = originalDate.toLocaleString('en-US', options);

    return desiredDateFormat;
}

export function convertPrice(value) {
    if(value===0 || value===null) return 0;
    const isNegative = value < 0;
    value = Math.abs(value);

    if (value >= 10000000) {
        return (isNegative ? '-' : '') + (value / 10000000).toFixed(2) + ' Cr';
    } else if (value >= 100000) {
        return (isNegative ? '-' : '') + (value / 100000).toFixed(2) + ' L';
    } else if (value >= 1000) {
        return (isNegative ? '-' : '') + (value / 1000).toFixed(2) + ' K';
    } else {
        return (isNegative ? '-' : '') + value.toFixed(2);
    }
}