import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function ArrowUpIcon({ width = 15, height = 15, color: iconColor, ...restProps }) {
   const theme = useTheme();
   const color = iconColor || theme.colors.color.primary;
   return (
      <SVGContainer {...restProps}>
<svg width={width} height={height} color={color} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 16C7 16.5523 7.44772 17 8 17C8.55228 17 9 16.5523 9 16L7 16ZM8.70711 0.292892C8.31658 -0.0976314 7.68342 -0.0976315 7.29289 0.292892L0.928933 6.65685C0.538408 7.04738 0.538408 7.68054 0.928933 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292892ZM9 16L9 0.999999L7 0.999999L7 16L9 16Z" fill="currentColor"/>
</svg>
      </SVGContainer>
   );
}

export default ArrowUpIcon;