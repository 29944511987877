import { ThemeProvider } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import RoutesComponent from './Routes/Routes';
import createPrimaryTheme from './theme';
import Styles from './GlobalStyle';
import './styleWithoutTheme.css';
import { connect } from 'react-redux';
import Store from 'Redux/Store';
import { setCheckAuth } from 'Redux/Actions/Auth';
import { Loader } from 'Components';

Store.dispatch(setCheckAuth());

function App(props) {

  const { apiCallProgress } = props;

  const theme = createPrimaryTheme('light');

  return (
    <>
      {/* {apiCallProgress && <Loader />} */}
      {!!theme ? <ThemeProvider theme={theme}>
        <GlobalStyles styles={Styles(theme)} />
        <RoutesComponent />
      </ThemeProvider> : <div />
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  token: state.login.token,
  user: state.login.user,
  apiCallProgress: state.ui.apiCallProgress
})


export default connect(mapStateToProps)(App);
