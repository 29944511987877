import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function MyLeagueDashboardIcon({ width = 106, height = 90, color: iconColor, ...restProps }) {
   const theme = useTheme();
   const color = iconColor || theme.colors.color.primary;
   return (
      <SVGContainer {...restProps}>

<svg width={width}
            height={height}
            color={color} viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M90.3632 26.3841C90.3632 32.8073 90.3492 39.2306 90.3685 45.6539C90.3755 48.1979 89.3294 50.1743 87.2479 51.5813C85.897 52.4959 84.3324 52.7201 82.7292 52.7184C78.4663 52.7149 74.2034 52.7184 69.9423 52.7184C65.5637 52.7184 61.1835 52.7184 56.8049 52.7184C55.9867 52.7184 55.1754 52.6798 54.3695 52.5029C51.2577 51.8178 49.1587 49.4174 48.9309 46.2443C48.9011 45.8378 48.9081 45.4278 48.9081 45.0178C48.9081 32.5217 48.9116 20.0257 48.9011 7.53133C48.9011 6.10861 49.0238 4.72443 49.7719 3.47693C51.0702 1.31306 52.9608 0.109363 55.5154 0.0638084C57.2079 0.0340224 58.9022 0.0532961 60.5947 0.0532961C68.0693 0.0532961 75.5455 0.18645 83.0166 0.00247793C87.2882 -0.102649 90.5191 3.14928 90.407 7.46474C90.2441 13.7671 90.365 20.0765 90.365 26.3823L90.3632 26.3841ZM84.3552 26.4279C84.3552 20.4199 84.3587 14.4119 84.3534 8.40212C84.3517 5.9404 84.2571 5.84929 81.7918 5.84753C73.7409 5.84403 65.6917 5.84578 57.6407 5.84753C57.2324 5.84753 56.8207 5.83177 56.416 5.87207C55.2999 5.98245 55.0353 6.26104 54.9424 7.40868C54.9144 7.75735 54.9267 8.10777 54.9267 8.45819C54.9267 20.3586 54.9231 32.2589 54.9267 44.1593C54.9267 46.6263 55.0002 46.6946 57.497 46.6964C65.6058 46.7016 73.7146 46.6964 81.8216 46.6964C82.1125 46.6964 82.4051 46.7051 82.6959 46.6859C84.0398 46.6018 84.2641 46.374 84.3412 44.9775C84.3675 44.5115 84.3517 44.0437 84.3517 43.5776C84.3517 37.8605 84.3517 32.1433 84.3517 26.4279H84.3552Z" fill="currentColor"/>
<path d="M41.9095 63.7698C41.9095 69.7253 41.9043 75.6807 41.9148 81.6361C41.9148 82.6927 41.8464 83.7369 41.6011 84.7654C40.846 87.928 38.4543 89.8974 35.1884 89.9359C31.2181 89.9832 27.2478 89.9517 23.2775 89.9534C18.0807 89.9534 12.8805 89.8133 7.68893 89.9972C3.41377 90.1479 0.207403 87.0134 0.245949 82.5507C0.286248 77.9392 0.252954 73.3259 0.252954 68.7125C0.252954 61.2976 0.252954 53.8826 0.252954 46.4677C0.252954 45.942 0.245949 45.4164 0.256462 44.8908C0.356332 40.1706 3.10715 37.3391 7.82034 37.3111C16.6948 37.2585 25.5693 37.2866 34.4437 37.3041C37.0614 37.3094 39.22 38.2993 40.7163 40.5175C41.7448 42.0418 41.899 43.7904 41.9025 45.5548C41.9148 51.6277 41.9078 57.6987 41.9078 63.7716L41.9095 63.7698ZM6.26272 63.5771C6.26272 69.4099 6.26272 75.2444 6.26272 81.0772C6.26272 81.5433 6.24695 82.0111 6.27323 82.4771C6.34682 83.8088 6.61139 84.0646 7.97103 84.1487C8.3197 84.1697 8.67013 84.1592 9.02055 84.1592C17.0715 84.1592 25.1207 84.1627 33.1717 84.1592C35.7806 84.1592 35.8927 84.0418 35.8927 81.3488C35.8963 69.6236 35.8927 57.8985 35.8927 46.1733C35.8927 45.7072 35.9103 45.2377 35.8647 44.7751C35.7543 43.659 35.4775 43.4067 34.3158 43.3226C33.9672 43.2981 33.6168 43.3104 33.2663 43.3104C25.1575 43.3104 17.0487 43.3104 8.93995 43.3104C8.76474 43.3104 8.58952 43.3104 8.41431 43.3104C6.44493 43.3401 6.29776 43.4803 6.26272 45.3796C6.25571 45.7878 6.26097 46.1961 6.26097 46.6043C6.26097 52.2637 6.26097 57.9212 6.26097 63.5806L6.26272 63.5771Z" fill="currentColor"/>
<path d="M69.5998 89.9462C65.0461 89.9462 60.4871 89.8288 55.9386 89.983C52.2381 90.1092 48.825 87.1148 48.8863 82.9202C48.9617 77.6656 48.8863 72.411 48.9091 67.1547C48.9266 62.7411 51.4496 60.0691 55.858 59.7415C56.3223 59.7064 56.7918 59.7152 57.2579 59.7152C65.4911 59.7152 73.7243 59.7152 81.9575 59.71C82.9527 59.71 83.9374 59.766 84.9186 59.9587C87.9725 60.5562 90.2888 63.0933 90.3291 66.1928C90.4027 71.973 90.4202 77.755 90.3256 83.5335C90.266 87.2287 87.4784 89.8902 83.7902 89.9287C79.0612 89.9778 74.3305 89.941 69.6016 89.941V89.9445L69.5998 89.9462ZM69.6208 84.1537C73.8207 84.1537 78.0222 84.1642 82.222 84.1485C84.1371 84.1415 84.3351 83.9522 84.3421 82.0389C84.3596 77.3135 84.3596 72.588 84.3421 67.8626C84.3351 65.9335 84.1406 65.7372 82.2378 65.7337C73.8364 65.7215 65.4368 65.7215 57.0354 65.7337C55.0993 65.7355 54.9346 65.9019 54.9276 67.845C54.9101 72.5705 54.9118 77.2959 54.9276 82.0214C54.9346 83.975 55.1011 84.1415 57.0196 84.1485C61.2194 84.1642 65.421 84.1537 69.6208 84.1537Z" fill="currentColor"/>
<path d="M20.9241 30.29C16.6033 30.29 12.2826 30.2813 7.96192 30.2953C6.83881 30.2988 5.7525 30.1516 4.69948 29.7591C2.57591 28.9672 1.21452 27.4726 0.611788 25.2965C0.392773 24.5081 0.259611 23.7003 0.259611 22.8768C0.259611 17.6801 0.252606 12.4833 0.263118 7.28651C0.266623 5.86379 0.525937 4.47611 1.31614 3.2724C2.64775 1.2417 4.49623 0.0607843 6.99825 0.0607843C16.3984 0.0625364 25.8002 0.0380008 35.2003 0.0765473C38.5118 0.0905642 41.0874 2.36831 41.6516 5.63776C41.8005 6.4963 41.8934 7.37762 41.8987 8.24842C41.9232 12.8617 41.9127 17.4733 41.9056 22.0866C41.9056 22.6105 41.8794 23.1379 41.8163 23.6583C41.3099 27.8178 38.7851 30.1779 34.5871 30.2708C31.0846 30.3479 27.5803 30.29 24.0779 30.2935C23.0266 30.2935 21.9753 30.2935 20.9241 30.2935V30.29ZM20.9153 24.2698C25.1186 24.2698 29.322 24.2768 33.5253 24.2663C35.6839 24.261 35.8819 24.0858 35.8889 22.0183C35.9064 17.3489 35.9082 12.6778 35.8871 8.00838C35.8784 6.13537 35.605 5.85854 33.7443 5.85503C25.3376 5.83926 16.931 5.84101 8.52434 5.85328C6.55847 5.85678 6.27813 6.13362 6.27112 8.07496C6.2536 12.8039 6.2536 17.5329 6.27112 22.2601C6.27813 24.0648 6.47436 24.254 8.30532 24.2628C12.5087 24.2803 16.712 24.268 20.9153 24.2698Z" fill="currentColor"/>
</svg>
      </SVGContainer>
   );
}

export default MyLeagueDashboardIcon;
